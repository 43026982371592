/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'
import Button from 'components/shared/Button'
import { LangContext } from 'contexts/LangContext'
import { PopupContext } from 'contexts/PopupContext'

const CookiesWrapper = styled.aside`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  display: block;
  background: ${({ theme }) => theme.colors.gray200};
  transition: 0.3s;
  opacity: 1;
  padding: 10px 0;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5px 20px;
  height: 100%;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
`

const CookiesButton = styled(Button)`
  margin: 0;
  min-width: 200px !important;
`

const CookiesText = styled.p`
  text-align: justify;
  ${({ theme }) => theme.mq.md} {
    text-align: left;
    padding-right: 15px;
    padding-bottom: 0;
  }
`

const CookiesInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CookiesLinks = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 0;
  }
`

const CookiesLink = styled.span`
  position: relative;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.bold};
  cursor: pointer;
  transition: 0.3s;
  margin: 10px 0;
  &:hover:after {
    transform: scale(1);
    transform-origin: center left;
  }
  &:after {
    content: '';
    height: 0.2rem;
    background-color: ${({ theme }) => theme.colors.yellow};
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: center right;
  }
`

const Spacer = styled.span`
  display: block;
  width: 2px;
  height: 16px;
  margin: 4px 6px 0;
  background: ${({ theme }) => theme.colors.dark};
`

const cookiesContent = {
  EN: {
    content: `We use cookies to provide you with the best possible experience. By continuing to use our site, you agree to use of cookies.`,
    button: `Understand`,
  },
  DE: {
    content: `Wir nutzen Cookies, Pixel und vergleichbare Technologien um Ihnen die bestmögliche Nutzung unserer Webseite zu ermöglichen. Mehr erfahren Sie in unseren Datenschutzeinstellungen. Mit Klick auf „Verstehen“ willigen Sie in die Verwendung dieser Technologien ein. Die Einwilligung können Sie jederzeit mit Wirkung für die Zukunft einzeln widerrufen oder ändern.`,
    button: `Verstehen`,
  },
}

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const { lang } = useContext(LangContext)
  const { showPrivacyPolicy, showImpressum } = useContext(PopupContext)

  const acceptCookies = () => {
    Cookie.set('victoriade-cookies', true)
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)
    if (localStorage) {
      localStorage.setItem('victoriade-cookies', true)
    }
  }

  useEffect(() => {
    if (localStorage) {
      const value = localStorage.getItem('victoriade-cookies')
      if (value && Boolean(value) === true) {
        setVisible(false)
        setTimeout(() => setRemoveAlert(true), 500)
      } else {
        setVisible(true)
      }
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <CookiesContainer>
            <CookiesInnerWrapper>
              <CookiesText>{cookiesContent[lang].content}</CookiesText>
              <CookiesLinks>
                <CookiesLink onClick={showPrivacyPolicy}>
                  {lang === 'EN' && 'Privacy Policy'}
                  {lang === 'DE' && 'Datenschutz'}
                </CookiesLink>
                <Spacer />
                <CookiesLink onClick={showImpressum}>
                  {lang === 'EN' && 'Imprint'}
                  {lang === 'DE' && 'Impressum'}
                </CookiesLink>
              </CookiesLinks>
            </CookiesInnerWrapper>
            <CookiesButton red onClick={acceptCookies}>
              {cookiesContent[lang].button}
            </CookiesButton>
          </CookiesContainer>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
