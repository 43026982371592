import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Content from 'components/shared/Content'
import Links from 'components/Navigation/Links'
import Logo from 'components/shared/Logo'
import { LangContext } from 'contexts/LangContext'
import { PopupContext } from 'contexts/PopupContext'
import PDFFile from 'components/Footer/Ankaufprofil.pdf'

const Wrapper = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -5px 5px -5px #ddd;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.bold};
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
`

const InnerOptions = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mq.lg} {
    margin: 20px 0 0;
    flex-direction: row;
    justify-content: space-between;
  }
`

const Option = styled.span`
  position: relative;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.regular};
  cursor: pointer;
  transition: 0.3s;
  margin: 10px 0;
  &:hover:after {
    transform: scale(1);
    transform-origin: center left;
  }
  &:after {
    content: '';
    height: 0.2rem;
    background-color: ${({ theme }) => theme.colors.yellow};
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: center right;
  }
  ${({ theme }) => theme.mq.lg} {
    margin: 0;
    &:first-of-type {
      margin-right: 5px;
    }
  }
`

const Bold = styled(Option)`
  padding: 20px 0 0;
  margin: 10px 0 0;
  border-top: 1px solid #ddd;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.superBold};
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: none;
  ${({ theme }) => theme.mq.lg} {
    margin: 0;
    border: none;
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`

const Footer = ({ hideNav }) => {
  const { lang } = useContext(LangContext)
  const { showPrivacyPolicy, showImpressum } = useContext(PopupContext)

  return (
    <Wrapper>
      <Content>
        <InnerWrapper>
          <Logo big />
          <Links close={hideNav} />
          <Options>
            <Bold as="a" download="Ankaufprofil" href={PDFFile}>
              {lang === 'EN' && 'Download acquisition profile'}
              {lang === 'DE' && 'Akquisitionsprofil herunterladen'}
            </Bold>
            <InnerOptions>
              <Option onClick={showPrivacyPolicy}>
                {lang === 'EN' && 'Privacy Policy'}
                {lang === 'DE' && 'Datenschutzerklärung'}
              </Option>
              <Option onClick={showImpressum}>
                {lang === 'EN' && 'Imprint'}
                {lang === 'DE' && 'Impressum'}
              </Option>
            </InnerOptions>
          </Options>
        </InnerWrapper>
      </Content>
    </Wrapper>
  )
}

Footer.propTypes = {
  hideNav: PropTypes.func.isRequired,
}

export default Footer
