import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import GlobalStyle from 'theme/GlobalStyle'
import { theme as mainTheme } from 'theme/mainTheme'
import Navigation from 'components/Navigation/Navigation'
import Cookies from 'components/Cookies/Cookies'
import Popup from 'components/shared/Popup'
import LangProvider from 'contexts/LangContext'
import PopupProvider from 'contexts/PopupContext'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`

const MainLayout = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    <LangProvider>
      <PopupProvider>
        <Wrapper>
          <Popup />
          {children}
          <Navigation />
          <Cookies />
        </Wrapper>
      </PopupProvider>
    </LangProvider>
  </ThemeProvider>
)

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default MainLayout
