import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Content from 'components/shared/Content'
import Icon from 'components/shared/Icon'
import Footer from 'components/Footer/Footer'
import Links from 'components/Navigation/Links'
import Sidenav from 'components/Navigation/Sidenav'
import Logo from 'components/shared/Logo'
import phoneIcon from 'assets/icons/phone_icon.svg'
import menuIcon from 'assets/icons/burger.svg'
import useMedia from 'hooks/useMedia'
import LangBtns from 'components/Navigation/LangBtns'

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 9;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: transform ease-in-out 250ms;
  box-shadow: 0 -1px #ddd inset;
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ theme }) => theme.bold};
`

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: flex-end;
    & > ul {
      justify-content: flex-end;
      li {
        margin: 0 5px;
        @media (min-width: 1060px) {
          margin: 0 10px;
        }
        ${({ theme }) => theme.mq.xl} {
          margin: 0 20px;
        }
        ${({ theme }) => theme.mq.xxl} {
          margin: 0 30px;
        }
      }
    }
  }
`

const MenuBtn = styled.div`
  display: block;
  cursor: pointer;
  margin-left: 15px;
  ${({ theme }) => theme.mq.xs} {
    margin-left: 30px;
  }
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`

const Number = styled.span`
  margin-left: 12px;
  white-space: nowrap;
  color: inherit;
  font-weight: ${({ theme }) => theme.superBold};
  font-size: ${({ theme }) => theme.fontSize.s};
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`

const StyledContent = styled(Content)`
  max-width: 1920px;
  padding: 0 10px !important;
  ${({ theme }) => theme.mq.s} {
    padding: 0 20px !important;
  }
`

const Phone = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.red};
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.red200};
  }
`

const IconWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`

const HideBelowLG = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`

const Navigation = () => {
  const [isNavHidden, setIsNavHidden] = useState(false)
  const [isSidenavVisible, setIsSidenavVisible] = useState(false)

  let prevScroll = 0

  const handleScroll = e => {
    const window = e.currentTarget

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false)
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true)
    }
    prevScroll = window.scrollY
  }

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e))

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e))
    }
  }, [])

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true)
  }

  const closeSidenav = () => {
    setIsSidenavVisible(false)
    setTimeout(hideNav, 800)
    document.body.style.overflow = 'auto'
  }

  const openSidenav = () => {
    setIsSidenavVisible(true)
    document.body.style.overflow = 'hidden'
  }

  const matchesLG = useMedia('(min-width: 992px)')
  const matchesXS = useMedia('(min-width: 420px)')

  return (
    <>
      {!matchesLG && (
        <Sidenav isVisible={isSidenavVisible} close={closeSidenav} />
      )}
      <Wrapper navHidden={isNavHidden}>
        <StyledContent>
          <InnerWrapper>
            <Logo />
            <LinksWrapper>
              <Links close={() => setTimeout(hideNav, 800)} />
            </LinksWrapper>
            <InnerWrapper>
              <Phone href="tel:+49 3377 997 9999">
                <IconWrapper>
                  <Icon
                    size={matchesLG ? 30 : 22}
                    src={phoneIcon}
                    alt="phone"
                  />
                </IconWrapper>
                <Number>+49 3377 997 9999</Number>
              </Phone>
              <MenuBtn onClick={openSidenav}>
                <Icon size={matchesXS ? 30 : 26} src={menuIcon} />
              </MenuBtn>
            </InnerWrapper>
            <HideBelowLG>
              <LangBtns />
            </HideBelowLG>
          </InnerWrapper>
        </StyledContent>
      </Wrapper>
      <Footer hideNav={() => setTimeout(hideNav, 800)} />
    </>
  )
}

export default Navigation
