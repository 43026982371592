/* eslint-disable no-use-before-define */
import React, { useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'
import useOutsideClick from 'hooks/useOutsideClick'
import closeIcon from 'assets/icons/close.svg'
import { PopupContext } from 'contexts/PopupContext'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 10;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      & > ${Wrapper} {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
      }
    `}
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -40%);
  z-index: 20;
  overflow: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  ${({ theme }) => theme.mq.lg} {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 85%;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.dark};
  padding: 60px 20px 40px;
  ${({ theme }) => theme.mq.md} {
    padding: 100px;
  }
`

const HeadingWrapper = styled.div`
  text-align: center;
  ${({ theme }) => theme.mq.lg} {
    text-align: left;
  }
`

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 25px;
  padding: 30px 0 0 0;
  a {
    color: #4287f5;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      color: #72a1ed;
    }
  }
`

const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: 0;
  ${({ theme }) => theme.mq.md} {
    top: 40px;
    right: 40px;
  }
`

const Popup = () => {
  const { popupState, closePopup } = useContext(PopupContext)
  const popupRef = useRef(null)
  useOutsideClick(popupRef, closePopup)

  const { visible: isVisible, content, heading } = popupState

  return (
    <Overlay isVisible={isVisible}>
      <Wrapper ref={popupRef}>
        <InnerWrapper>
          <CloseBtn onClick={closePopup}>
            <Icon size={20} src={closeIcon} alt="close" />
          </CloseBtn>
          <HeadingWrapper>
            <Heading title={heading} />
          </HeadingWrapper>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  )
}

export default Popup
