import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'components/shared/Icon'
import dotsSvg from 'assets/icons/illu_find_out_more.svg'
import dots2Svg from 'assets/icons/illu_exampless.svg'
import dots3Svg from 'assets/icons/illu_contact.svg'

const IconWrapper = styled.div`
  display: none;
  position: absolute;
  width: 1920px;
  height: 40px;
  top: 50%;
  left: -20%;
  transform: translateY(-50%);
  pointer-events: none;
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`

const Icon2Wrapper = styled(IconWrapper)`
  display: none;
  width: 620px;
  left: auto;
  right: -10%;
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
  ${({ offset }) =>
    offset &&
    css`
      right: -15%;
    `}
`

const Icon3Wrapper = styled(IconWrapper)`
  display: none;
  width: 1920px;
  left: -15%;
  right: auto;
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`

const Title = styled.h2`
  position: relative;
  text-transform: ${({ nouppercase }) => (nouppercase ? 'none' : 'uppercase')};
  font-weight: ${({ theme }) => theme.superBold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  line-height: 32px;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme, big }) =>
      big ? theme.fontSize.xlg : theme.fontSize.xl};
    line-height: 40px;
  }
  ${({ bigFrom }) =>
    bigFrom &&
    css`
      ${({ theme }) => theme.mq[bigFrom]} {
        font-size: ${({ theme }) => theme.fontSize.xlg} !important;
      }
    `}
  ${({ small }) =>
    small &&
    css`
      font-size: ${({ theme }) => theme.fontSize.lg};
      line-height: 2.6rem;
      ${({ theme }) => theme.mq.s} {
        font-size: ${({ theme }) => theme.fontSize.xl};
      }
    `}
`

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.red};
`

const Heading = ({
  id,
  bigFrom,
  big,
  title,
  nouppercase,
  highlight,
  order,
  small,
}) => (
  <Title
    small={small}
    bigFrom={bigFrom}
    nouppercase={nouppercase}
    id={id}
    big={big}
  >
    {order === 'highlightFirst' ? (
      <>
        <Highlight>{highlight}</Highlight>
        {` ${title}`}
      </>
    ) : (
      <>
        {`${title} `}
        <Highlight>{highlight}</Highlight>
      </>
    )}

    {(highlight && highlight.toLowerCase().includes('projects')) ||
      (highlight.toLowerCase().includes('angebote') && (
        <IconWrapper>
          <Icon full src={dotsSvg} alt="dots" />
        </IconWrapper>
      ))}
    {highlight &&
      (highlight.toLowerCase().includes('poland') ||
        highlight.toLowerCase().includes('polen')) && (
        <Icon2Wrapper
          offset={highlight.toLowerCase().includes('polen') ? 1 : 0}
        >
          <Icon full src={dots2Svg} alt="dots" />
        </Icon2Wrapper>
      )}
    {title &&
      (title.toLowerCase().includes('contact') ||
        title.toLowerCase().includes('kontakt')) && (
        <Icon3Wrapper>
          <Icon full src={dots3Svg} alt="dots" />
        </Icon3Wrapper>
      )}
  </Title>
)

Heading.propTypes = {
  big: PropTypes.bool,
  nouppercase: PropTypes.bool,
  small: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  highlight: PropTypes.string,
  bigFrom: PropTypes.string,
  order: PropTypes.string,
}

Heading.defaultProps = {
  id: null,
  big: false,
  nouppercase: false,
  small: false,
  title: '',
  highlight: '',
  bigFrom: '',
  order: '',
}

export default Heading
