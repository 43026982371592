import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { LangContext } from 'contexts/LangContext'
import Button from 'components/shared/Button'

const LangWrapper = styled.div`
  margin-left: 20px;
  display: flex;
`

const Lang = styled(Button)`
  display: block;
  width: 50px !important;
  padding: 10px 0 !important;
  border-radius: 4px;
  margin-left: 5px !important;
`

const LangBtns = () => {
  const [langLink, setLangLink] = useState('/')
  const { lang } = useContext(LangContext)

  const locationPath =
    typeof window !== 'undefined' ? window.location.pathname : ''

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentPath = window.location.pathname
      const prefix = currentPath.slice(1, 3)
      if (prefix === 'en') {
        const pathWithoutPrefix = currentPath.slice(3, currentPath.length)
        if (!pathWithoutPrefix) setLangLink('/')
        else setLangLink(pathWithoutPrefix)
      } else setLangLink(currentPath)
    }
  }, [locationPath])

  return (
    <LangWrapper>
      <Lang as={Link} to={`/en${langLink}`} secondary={lang !== 'EN'}>
        EN
      </Lang>
      <Lang as={Link} to={`${langLink}`} secondary={lang !== 'DE'}>
        DE
      </Lang>
    </LangWrapper>
  )
}

export default LangBtns
