import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Button = styled.button`
  display: block;
  padding: 16px;
  background: ${({ theme, secondary }) =>
    secondary ? theme.colors.white : theme.colors.dark};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.dark : theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.superBold};
  border-radius: 30px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  background-size: 300% 100%;
  box-shadow: ${({ secondary }) =>
    secondary ? 'none' : '0 1px 30px -5px rgba(0, 0, 0, 0.2)'};
  border: 1px solid
    ${({ theme, secondary }) =>
      secondary ? theme.colors.dark : 'transparent'};
  @media (hover: hover) {
  &:hover {
    background: ${({ theme, secondary }) =>
      secondary ? theme.colors.yellow : theme.colors.yellow};
    color: ${({ theme, secondary }) =>
      secondary ? theme.colors.white : theme.colors.white};
    border-color: ${({ theme, secondary }) => secondary && theme.colors.yellow};
  }
  }
  ${({ theme }) => theme.mq.s} {
    width: 180px;
    margin: auto;
  }
  ${({ outline }) =>
    outline &&
    css`
      padding: 10px 20px;
      box-shadow: none;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray};
      border: 1px solid ${({ theme }) => theme.colors.gray100};
      @media (hover: hover) {
        &:hover {
          background: ${({ theme, secondary }) =>
            secondary ? theme.colors.white : theme.colors.yellow};
          color: ${({ theme, secondary }) =>
            secondary ? theme.colors.dark : theme.colors.white};
          border-color: ${({ theme, secondary }) =>
            secondary && theme.colors.dark};
        }
      }
      ${({ theme }) => theme.mq.s} {
        width: auto;
      }
    `}
  ${({ dark }) =>
    dark &&
    css`
      background: ${({ theme }) => theme.colors.dark};
      color: ${({ theme }) => theme.colors.white};
      @media (hover: hover) {
        &:hover {
          background: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.dark};
        }
      }
    `}
  ${({ red }) =>
    red &&
    css`
      background: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.white};
      @media (hover: hover) {
        &:hover {
          background: ${({ theme }) => theme.colors.dark};
          color: ${({ theme }) => theme.colors.white};
        }
      }
      ${({ theme }) => theme.mq.s} {
        width: 240px;
      }
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme, secondary }) =>
        secondary ? theme.colors.white : theme.colors.yellow};
      color: ${({ theme, secondary }) =>
        secondary ? theme.colors.dark : theme.colors.white};
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      height: 53px;
      &:before {
        content: '';
        position: absolute;
        top: 27%;
        left: 45%;
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
    `}
`

export default Button
