import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scrollToSection from 'utils/scrollToSection'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import useLocation from 'hooks/useLocation'
import { LangContext } from 'contexts/LangContext'

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`

const LinkItem = styled.li`
  position: relative;
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin: 20px 0;
  cursor: pointer;
  transition: 0.3s;
  &:hover:after {
    transform: scale(1);
    transform-origin: center left;
  }
  &:after {
    content: '';
    height: 0.2rem;
    background-color: ${({ theme }) => theme.colors.yellow};
    position: absolute;
    top: calc(100% + 0.8rem);
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: center right;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.md};
    margin: auto;
  }
`

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`

const Links = ({ close }) => {
  const location = useLocation()
  const { lang } = useContext(LangContext)

  let offerText = lang === 'EN' && 'Our projects'
  offerText = lang === 'DE' ? 'Unser Angebot' : offerText

  let aboutText = lang === 'EN' && 'About us'
  aboutText = lang === 'DE' ? 'Über uns' : aboutText

  let experienceText = lang === 'EN' && 'Our experience'
  experienceText = lang === 'DE' ? 'Unsere Erfahrung' : experienceText

  let contactText = lang === 'EN' && 'Contact us'
  contactText = lang === 'DE' ? 'Kontakt' : contactText

  return (
    <>
      <List>
        <LinkItem onClick={close}>
          {location !== '/' && location !== '/en' ? (
            <StyledLink
              as={AnchorLink}
              to={lang === 'EN' ? '/en/#offer' : '/#offer'}
              title={offerText}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#offer')}>
              {offerText}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={close}>
          {location !== '/' && location !== '/en' ? (
            <StyledLink
              as={AnchorLink}
              to={lang === 'EN' ? '/en/#about' : '/#about'}
              title={aboutText}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#about')}>
              {aboutText}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={close}>
          {location !== '/' && location !== '/en' ? (
            <StyledLink
              as={AnchorLink}
              to={lang === 'EN' ? '/en/#experience' : '/#experience'}
              title={experienceText}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#experience')}>
              {experienceText}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={close}>
          {location !== '/' && location !== '/en' ? (
            <StyledLink
              as={AnchorLink}
              to={lang === 'EN' ? '/en/#contact' : '/#contact'}
              title={contactText}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#contact')}>
              {contactText}
            </StyledLink>
          )}
        </LinkItem>
      </List>
    </>
  )
}

Links.propTypes = {
  close: PropTypes.func,
}

Links.defaultProps = {
  close: () => null,
}

export default Links
