import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const LangContext = createContext()

const LangProvider = ({ children }) => {
  const [lang, setLang] = useState('DE')

  const setAppLang = language => {
    localStorage.setItem('lang', JSON.stringify({ lang: language }))
    setLang(language)
  }

  useEffect(() => {
    const localLang = JSON.parse(localStorage.getItem('lang'))
    if (localLang) {
      setLang(localLang.lang)
    }
  }, [])

  return (
    <LangContext.Provider value={{ lang, setAppLang }}>
      {children}
    </LangContext.Provider>
  )
}

LangProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default LangProvider
