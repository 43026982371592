import React, { createContext, useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { LangContext } from 'contexts/LangContext'

export const PopupContext = createContext()

const PopupProvider = ({ children }) => {
  const [popupState, setPopupState] = useState({
    heading: '',
    content: '',
    visible: false,
  })
  const { lang, setAppLang } = useContext(LangContext)

  const {
    wpgraphql: { english, german },
  } = useStaticQuery(graphql`
    {
      wpgraphql {
        english: page(id: "cG9zdDoxNDc=") {
          PageHome {
            policyText
            impressumText
            impressumTitle
            policyTitle
          }
        }
        german: page(id: "cG9zdDo5") {
          PageHome {
            policyText
            impressumText
            impressumTitle
            policyTitle
          }
        }
      }
    }
  `)

  const locationPath =
    typeof window !== 'undefined' ? window.location.pathname : ''

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const prefix = window.location.pathname.slice(0, 4)
      if (window.location.pathname.length > 3) {
        if (prefix[0] === '/' && prefix[3] === '/') {
          setAppLang(`${prefix[1]}${prefix[2]}`.toUpperCase())
        } else {
          setAppLang('DE')
        }
      } else if (window.location.pathname.length === 3) {
        setAppLang(`${prefix[1]}${prefix[2]}`.toUpperCase())
      } else {
        setAppLang('DE')
      }
    }
  }, [locationPath])

  let currentLangData = lang === 'EN' && english
  currentLangData = lang === 'DE' ? german : currentLangData

  const {
    policyText,
    impressumText,
    impressumTitle,
    policyTitle,
  } = currentLangData.PageHome

  const closePopup = () => {
    setPopupState({ ...popupState, visible: false })
    document.body.style.overflow = 'auto'
  }

  const showPrivacyPolicy = () => {
    setPopupState({ heading: policyTitle, content: policyText, visible: true })
    document.body.style.overflow = 'hidden'
  }

  const showImpressum = () => {
    setPopupState({
      heading: impressumTitle,
      content: impressumText,
      visible: true,
    })
    document.body.style.overflow = 'hidden'
  }

  return (
    <PopupContext.Provider
      value={{ popupState, closePopup, showPrivacyPolicy, showImpressum }}
    >
      {children}
    </PopupContext.Provider>
  )
}

PopupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default PopupProvider
